import React from "react"
import { Link } from "gatsby"

const ContactContent = () => (

	<div className="container ng-scope">

	    <div className="row">

	        <div className="col-lg-12">
	            <h1 className="pb-2 mt-4 mb-2 border-bottom">Contact
	                <small> We'd Love to Hear From You!</small>
	            </h1>
	        </div>

	        <div className="col-lg-12">
	            <iframe width="100%" height="300px" frameBorder="0" scrolling="no" marginHeight="0" marginWidth="0" src="http://maps.google.com/maps?hl=en&amp;ie=UTF8&amp;ll=37.0625,-95.677068&amp;spn=56.506174,79.013672&amp;t=m&amp;z=4&amp;output=embed"></iframe>
	        </div>

	    </div>
	    

	    <div className="row">

	        <div className="col-sm-8">
	            <h3>Let's Get In Touch!</h3>

	            <p>Lid est laborum dolo rumes fugats untras. Etharums ser quidem rerum facilis dolores nemis omnis fugats
	                vitaes nemo minima rerums unsers sadips amets. Sed ut perspiciatis unde omnis iste natus error sit
	                voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore
	                veritatis et quasi architecto beatae vitae dicta sunt explicabo.</p>

	            <form role="form" method="POST" action="#" className="ng-pristine ng-valid">
	                <div className="row">
	                    <div className="form-group col-lg-4">
	                        <label htmlFor="input1">Name</label>
	                        <input type="text" name="contact_name" className="form-control" id="input1" />
	                    </div>
	                    <div className="form-group col-lg-4">
	                        <label htmlFor="input2">Email Address</label>
	                        <input type="email" name="contact_email" className="form-control" id="input2" />
	                    </div>
	                    <div className="form-group col-lg-4">
	                        <label htmlFor="input3">Phone Number</label>
	                        <input type="phone" name="contact_phone" className="form-control" id="input3" />
	                    </div>
	                    <div className="clearfix"></div>
	                    <div className="form-group col-lg-12">
	                        <label htmlFor="input4">Message</label>
	                        <textarea name="contact_message" className="form-control" rows="6" id="input4"></textarea>
	                    </div>
	                    <div className="form-group col-lg-12">
	                        <input type="hidden" name="save" value="contact" />
	                        <button type="submit" className="btn btn-primary">Submit</button>
	                    </div>
	                </div>
	            </form>
	        </div>

	        <div className="col-sm-4">
	            <h3>Modern Business</h3>
	            <h4>A Start Bootstrap Template</h4>

	            <p>
	                5555 44th Street N.<br />
	                Bootstrapville, CA 32323<br />
	            </p>

	            <p><i className="fa fa-phone"></i> <abbr title="Phone">P</abbr>: (555) 984-3600</p>

	            <p><i className="fa fa-envelope-o"></i> <abbr title="Email">E</abbr>: <a href="mailto:feedback@startbootstrap.com">feedback@startbootstrap.com</a></p>

	            <p><i className="fa fa-clock-o"></i> <abbr title="Hours">H</abbr>: Monday - Friday: 9:00 AM to 5:00 PM</p>
	            <ul className="list-unstyled list-inline list-social-icons">
	                <li className="tooltip-social facebook-link"><a href="#facebook-page" data-toggle="tooltip" data-placement="top" title="Facebook"><i className="fa fa-facebook-square fa-2x"></i></a></li>
	                <li className="tooltip-social linkedin-link"><a href="#linkedin-company-page" data-toggle="tooltip" data-placement="top" title="LinkedIn"><i className="fa fa-linkedin-square fa-2x"></i></a></li>
	                <li className="tooltip-social twitter-link"><a href="#twitter-profile" data-toggle="tooltip" data-placement="top" title="Twitter"><i className="fa fa-twitter-square fa-2x"></i></a></li>
	                <li className="tooltip-social google-plus-link"><a href="#google-plus-page" data-toggle="tooltip" data-placement="top" title="Google+"><i className="fa fa-google-plus-square fa-2x"></i></a></li>
	            </ul>
	        </div>

	    </div>

	</div>

)

export default ContactContent
